<template>
    <div>
        <page-header></page-header>

        <!-- Heading and Filename -->
        <div class="header-section">
            <h1>IMPORT TRAINEES (DRY RUN)</h1>
            <p>{{ fileName }}</p>
        </div>

        <button class="back-button" @click="goBack">Back</button>

        <!-- Dry Run Information -->
        <div class="info-box">
            <span class="dryrun-span" title="dry run is a simulation where the database stays unaffected">Dry run</span> has
            been completed
            <li v-if="importedTrainees">Total records: {{ importedTrainees.length }}</li>
            <li v-if="importedTrainees">Invalid records: {{ invalidTraineeCount }}</li>
            <li v-if="importedTrainees">Valid trainees: {{ validTraineeCount }}</li>
            <p></p>
            <li>Trainees added to the database: 0</li>
        </div>

        <div class="issuesDisplay">
            <div v-if="Object.keys(groupedIssues).length">
                <div v-for="email in Object.keys(groupedIssues)" :key="email">
                    <!-- Display all issues for this trainee number -->
                    <ul>
                        <div class="solid-line"></div>
                        <li style="font-weight:bold">
                            Trainee - {{ email }}
                        </li>
                        <ul>
                            <li style="font-weight:bold" v-for="(issue, index) in groupedIssues[email]" :key="index">
                                {{ issue.message }}
                            </li>
                        </ul>
                    </ul>                    
                    <i style="color:grey; margin-left:20px;">Provided fields</i>
                    <div class="issue-list">
                        <ol>
                            <li><strong>First Name: &nbsp;</strong> {{ getTraineeByEmail(email).first_name }}</li>
                            <li><strong>Last Name: &nbsp;</strong> {{ getTraineeByEmail(email).last_name }}</li>
                            <li><strong>Email: &nbsp;</strong> {{ getTraineeByEmail(email).email }}</li>
                            <li><strong>Passport Number: &nbsp;</strong> {{ getTraineeByEmail(email).passportNo }}</li>
                            <li><strong>Category: &nbsp;</strong> {{ getTraineeByEmail(email).category }}</li>
                            <li><strong>Date of Birth: &nbsp;</strong> {{ getTraineeByEmail(email).date_of_birth }}</li>
                            <li><strong>Place of Birth: &nbsp;</strong> {{ getTraineeByEmail(email).place_of_birth }}</li>
                            <li><strong>UIN: &nbsp;</strong>{{ getTraineeByEmail(email).UIN }}</li>
                            <li><strong>Projects: &nbsp;</strong>{{ getTraineeByEmail(email).project }}</li>
                            <!-- <li><strong>Passport Photo: &nbsp;</strong> {{ getTraineeByEmail(email).passportPhoto }}</li>
                            <li><strong>Profile Photo: &nbsp;</strong> {{ getTraineeByEmail(email).profilePhoto }}</li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
  
<script>
import pageHeader from '@/components/Examiner/Header.vue';

export default {
    data() {
        return {
            csvFileName: "",  // Initialized as an empty string
            entireFileRows: [],
            previewRows: [],
            
        };
    },
    components:{
        pageHeader,
    },
    computed: {
        issues() {
            return this.$store.state.issues;
        },
        importedTrainees() {
            console.log(this.$store.state.importedTrainees);
            return this.$store.state.importedTrainees;
        },
        invalidTraineeCount() {
            const uniqueIssueTrainees = new Set(this.issues.map(issue => issue.email));
            return uniqueIssueTrainees.size;
        },
        validTraineeCount() {
            const uniqueImportedTrainees = new Set(this.importedTrainees.map(trainee => trainee.email));
            return uniqueImportedTrainees.size - this.invalidTraineeCount;
        },
        groupedIssues() {
            return this.issues.reduce((acc, issue) => {
                (acc[issue.email] = acc[issue.email] || []).push(issue);
                return acc;
            }, {});
        },
        fileName() {
            return this.$store.state.fileName;
        }
    },
    methods: {
        displayIssues() {
            console.log("Import Issues:", this.issues);
        },
        getRowNumber(issue) {
            const email = issue.email;
            // Find index in the importedTrainees
            const index = this.importedTrainees.findIndex(trainee => trainee.email === email);
            return index + 1; // +1 because CSV row numbers start from 1
        },
        getTraineeByEmail(email) {
            return this.importedTrainees.find(trainee => trainee.email === email);
        },
        goBack() {
      this.$router.push({ name: 'ImportTrainees' });
    },
    },
    mounted() {
        console.log("importedTrainees:", this.importedTrainees);
        console.log("issues from Vuex:", this.$store.state.issues);
    }
}
</script>

 
<style scoped>
.header-section {
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-section p{
    margin-right: 38%;
    margin-left: 30px;
    color:grey;
    font-size: 25px;
}

.header-section h1{
    margin-left:1%;
    font-size: 25px;
}

.info-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 0px 20px;
    color: #ca3f4b;
    font-weight: bold;
    background-color: #F8D7DA;
    width: 60%;
    height: 180px;
    text-align: left;
}


.issuesDisplay {
    text-align: left;
    margin-top:20px;
}


.issue-list {
    margin-left: 1%;
    background-color: rgb(250, 248, 248);
    width: 98%;
}

.dryrun-span {
    border: none;
}

.solid-line {
    border-top: 1px solid #acacac; /* You can adjust the color and thickness as needed */
    margin-top: 20px; /* Adjust the margin as needed */
    margin-bottom: 20px;
    margin-right: 40%;
}

.back-button {
  position: absolute;
  /* Adjust the top position as needed */
  right: 60px;
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>
  